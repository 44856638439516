import bootstrap from 'bootstrap'

let nav = document.getElementById('nav-content')

document.getElementById("nav-toggle").addEventListener("click", function() {
    document.getElementById('nav-content').classList.toggle('hidden')
})


// This will set any page with a data-img-src attribute to be styled as a background image. For csp
let allBGImgs = document.querySelectorAll('[data-img-src]')
allBGImgs.forEach(element => {
    var src = element.getAttribute("data-img-src");
    element.style.backgroundImage ="url("+src+")"
});
